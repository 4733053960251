export default [
  {
    title: "إدارة المركز",
    icon: "HomeIcon",
    // tag: "2",
    tagVariant: "light-warning",
    children: [
      {
        title: "الاستقبال",
        route: "reception",
        sub_router:"halls-details",
        slug:"centerManagement-reception"
        // icon:"SquareIcon"
      },
      {
        title: "المكتب",
        route: "office",
        slug:"centerManagement-office",
        slug: "centerManagement-office"
      },
      {
        title: 'إدارة المستفيدين',
        slug:'settings-usersManagement-beneficiary',
        route: 'manage-beneficiary',
      },
      {
        title: "إشغال القاعات",
        route: "halls-occupancy",
        slug:"centerManagement-holesOccupancy"
      },

      {
        title: "الطلبات",
        children: [
          {
            title: "طلبات الأرشيف",
            route: "archive-request",
            slug:"centerManagement-orders-archiveRequests"
          },
        ],
      },
      {
        title: "الحجوزات",
        children: [
          {
            title: "الإجراءات",
            route: "bookings-procedure",
            slug:"centerManagement-books-procedureAppointments"
          },
          {
            title: "استوديو التسجيل",
            route: "recording-studio",
            slug:"centerManagement-books-studio"
          },
          {
            title: " القاعات",
            route: "bookings-halls",
            slug:"centerManagement-books-hallAppointments"
          },
          {
            title: " الفعاليات",
            route: "bookings-events" ,
            sub_router:"bookings-event",
            slug:"centerManagement-books-eventAppointments"
          },
          {
            title: " النشاطات",
            route: "bookings-activites",
            sub_router:"bookings-activite",
            slug: "centerManagement-books-activityAppointments"
          },
          {
            title: "جلسات التوعية",
            route: "bookings-awareness-sessions",
            sub_router:"booking-awareness-sessions",
            slug:"centerManagement-books-studio"
          },
          {
            title: "متحف تفاعلي",
            route: "bookings-interactive-museum",
            sub_router:"booking-interactive-museum",
            slug:"centerManagement-books-interactiveMuseum"
          },
          {
            title: "تدريبات تراثية",
            route: "bookings-training-heritage",
            sub_router: "booking-training-heritage",
            slug: "centerManagement-books-traditionalTrainings"
          },
        ],
      },

      {
        title: "خدمات المركز",
        route: "manage-services",
        // icon:"SquareIcon"
        slug: "centerManagement-centerServices"
      },
      {
        title: "التقييم",
        route: "manage-evaluation",
        // icon:"SquareIcon"
        slug: "centerManagement-evaluation"
      },
      {
        title: "الزوار",
        route: "visitors",
        // icon:"SquareIcon"
        slug: "centerManagement-visitors"
      }
    ],
  },
];

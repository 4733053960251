export default [
    {
      title: 'الإعدادات',
      icon: 'SettingsIcon',
    //   tag: '2',
      tagVariant: 'light-warning',
      children: [
        {
          title: ' إدارة القاعات ',
          route: 'setting-halls',
          slug: 'settings-halls'
        },
        {
          title: ' إدارة المكاتب',
          slug:'settings-officeManagement',
          route: 'setting-office',
        },
        {
          title: 'حول التطبيق',
          slug:'settings-aboutApp',
          route: 'manage-about-app',
        },
        {
          title: 'حول المنارة',
          slug:'settings-aboutAlmanara',
          route: 'manage-about-manara',
        },
        {
          title:"التصنيفات",
          children: [
            {
              title:'تصنيفات الأرشيف',
              slug:'settings-categories-archiveCategories',
              route:'archive-categories'
            },
            {
              title:'تصنيفات الأنشطة',
              slug:'settings-categories-activityCategories',
              route:'activites-categories'
            },
            {
              title:'تصنيفات فرص العمل',
              slug:'settings-categories-jobOpportunities',
              route:'opportunities-categories'
            },
            {
              title:'انواع المرافق السياحية',
              slug:'settings-categories-touristFacilitiesTypes',
              route:'type-tourist-facilities'
            },
            {
              title:'تصنيفات الجولات السياحية',
              slug:'settings-categories-selfToursCategories',
              route:'tourist-tours-categories'
            },
          ]
        },


        {
          title: 'إصدار التطبيق',
          slug:'settings-versions',
          route: 'manage-versions',
        },
        {
          title: 'إدارة المستخدمين',
          children: [

            {
              title: 'إدارة مستخدمين المنارة',
              slug:'settings-usersManagement-user',
              route: 'manage-user-center',
            },
            {
              title: 'صلاحيات مستخدمين المنارة',
              slug:'settings-usersManagement-userPermissions',
              route: 'manage-role',
            }
          ]
        },
      ],
    },
  ]
  
<template>
  <ul>
  
    <component
      :is="resolveNavItemComponent(item)"
      v-for="item in items"
      :key="item.header || item.title"
      :item="item"
      v-if="checkIsHeaderGroup(item)"
          />
  </ul>
</template>

<script>
import { resolveVerticalNavMenuItemComponent as resolveNavItemComponent } from '@core/layouts/utils'
import { provide, ref } from '@vue/composition-api'
import VerticalNavMenuHeader from '../vertical-nav-menu-header'
import VerticalNavMenuLink from '../vertical-nav-menu-link/VerticalNavMenuLink.vue'
import VerticalNavMenuGroup from '../vertical-nav-menu-group/VerticalNavMenuGroup.vue'
import authHelpers from '@/utils/auth-helpers'

export default {
  components: {
    VerticalNavMenuHeader,
    VerticalNavMenuLink,
    VerticalNavMenuGroup,
  },
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  setup() {
    provide('openGroups', ref([]))

    const resolveNavComponent = item => {
      if (item.children) return 'horizontal-nav-menu-header-group'
      return 'horizontal-nav-menu-header-link'
    }
   
    const checkIsHeaderGroup= item =>{
      if (item.children) return item.children?.some(el => authHelpers.canNavigate(el.slug||''))
      return authHelpers.canNavigate(item.slug||'')
    }
    return {
      resolveNavItemComponent,
      resolveNavComponent,
      checkIsHeaderGroup
    }
  },
}
</script>

export default [
 {
  title: 'إدارة التطبيق',
  icon: 'HomeIcon',
  // tag: '2',
  tagVariant: 'light-warning',
  children: [
  {
    title: "الفعاليات",
    icon: "CheckCircleIcon",
    route: "dashboard-events",
    slug: "appManagement-events"
  },
  {
    title: "أنشطة المنارة",
    icon: "ActivityIcon",
    route: "dashboard-activite",
    slug:"appManagement-activities"
  },
  {
    title: "جلسات التوعية",
    route: "awareness-sessions",
    slug:"appManagement-awarenessSections"
  },
  {
    title: "متحف تفاعلي",
    route: "interactive-museum",
    slug: "appManagement-interactiveMuseum",
  },
  {
    title: "تدريبات تراثية",
    route: "training-heritage",
    slug: "appManagement-traditionalTrainings",
  },
  {
    title:"خدمات مجتمعية",
    children:[
      {
        title:"مخاتير",
        route:"mayors",
        slug:"appManagement-socialServices-mayors"
      },
      {
        title:"لجان مجتمعية",
        route:"community-committees",
        slug: "appManagement-socialServices-socialCommittees"
      }
    ]
  },
  {
    title:"التوعية القانونية",
    children: [

      {
        title: "معلومات عامة",
        route: "general-information",
        slug: "appManagement-LegalAwareness-generalInformation"
      },
    ],
  },
  {
    title: "اكتشف المدينة القديمة",
    children: [
      {
        title:"المرافق السياحية",
        route:"tourist-facilities",
        slug: "appManagement-discoverOldCity-touristFacilities"
      },
      {
        title:"جولات سياحية ذاتية",
        route:"self-tourist-tours",
        slug:"appManagement-discoverOldCity-selfTours"
      },
      {
        title: "المعالم الرئيسية",
        route:"main-attraction",
        slug:"appManagement-discoverOldCity-mainPlaces"
      }
    ]
  },
  {
    title: "إدارة الفرص",
    icon: "BriefcaseIcon",
    route: "dashboard-opportunities",
    slug: "appManagement-opportunities"
  },
  {
    title: "إدارة الإجراءات",
    icon: "SettingsIcon",
    route: "dashboard-procedure",
    slug: "appManagement-procedures"
  },
  // {
  //   title: "إدارة المحاضر الشاغرة",
  //   icon: "GridIcon",
  //   route: "dashboard-lecturers",
  //   sub_router:"lecturers-info"
  // },
  {
    title: "الخريطة التفاعلية",
    icon: "MapIcon",
    route: "interactive-map",
    slug: "appManagement-interactiveMap"
  },
  {
    title: "أرشيف",
    icon: "FolderIcon",
    route: "archives",
    slug: "appManagement-archive"
  },
  {
    title: "إشعارات التطبيق",
    icon: "BellIcon",
    route: "notifictions",
    slug: "appManagement-notifications"
  },
  {
    title:"المنتجات التمويلية",
    route:"financial-products",
    slug: "centerManagement-financingProducts"
  },

]}
];
